/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Slideshow, Title, Button, Fullmap, FullmapWrap, Subtitle, FullmapCover, Form } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="pb--60 pt--60" name={"4pbnupm46v3"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--shadow2 --left pb--20">
              
              <Image style={{"maxWidth":491}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/1d85a3bcc3f140b5aa4d3c87388e6d85_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/1d85a3bcc3f140b5aa4d3c87388e6d85_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/1d85a3bcc3f140b5aa4d3c87388e6d85_s=660x_.png 660w, https://cdn.swbpg.com/t/35535/1d85a3bcc3f140b5aa4d3c87388e6d85_s=860x_.png 860w, https://cdn.swbpg.com/t/35535/1d85a3bcc3f140b5aa4d3c87388e6d85_s=1400x_.png 1400w, https://cdn.swbpg.com/t/35535/1d85a3bcc3f140b5aa4d3c87388e6d85_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":136}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/cd01fec638a04322a5fec7073476026e_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/35535/cd01fec638a04322a5fec7073476026e_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Naše síla spočívá v našich značkách nejenom vyrobených v Německu. <br>Značky, které každý den milují a důvěřují jim miliony spotřebitelů, značky, které mají velký dopad na každodenní život lidí.&nbsp;<br>Zaujměte své zákazníky výjimečným sortimentem produktů\" <br>Zaručujeme vám, že naše produkty</span><span style=\"color: var(--black);\">&nbsp;jsou synonymem pro kvalitu. <br>Snažíme se vždy přinášet produkty, na které se můžete spolehnout a které vám přinesou tu nejlepší zkušenost. <br>Věříme, že si zamilujete, jak skvěle se s nimi pracuje!\"</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow name={"3plqc7qs3t1"} scale={0.5} effect={"fade"} autoplay={true} indicators={"none"} transitionDuration={100}>
          
          <Column className="css-kzmrf4 --border2 js-anim  --anim3 --full pb--60 pt--60" anim={"3"} border={"2"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cbc6aded470d43b6af8582a27bc4c732_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cbc6aded470d43b6af8582a27bc4c732_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cbc6aded470d43b6af8582a27bc4c732_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cbc6aded470d43b6af8582a27bc4c732_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cbc6aded470d43b6af8582a27bc4c732_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cbc6aded470d43b6af8582a27bc4c732_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cbc6aded470d43b6af8582a27bc4c732_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cbc6aded470d43b6af8582a27bc4c732_s=3000x_.png);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper >
                
                <Title className="title-box fs--36 title-box--invert" content={"O nás"}>
                </Title>

                <Text className="text-box text-box--invert" style={{"maxWidth":650}} content={"Vítejte v Geontrade Group, společnosti, která se zrodila z vášně pro kvalitu a inovaci ve světě spotřebního zboží. Naše cesta začala touhou posunout hranice toho, co se považuje za běžný standard, a přinést na trh produkty, které nejen uspokojí potřeby našich zákazníků, ale také je nadchnou."}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="css-h9blok --border2 --full pb--60 pt--60" border={"2"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/c2848dd263ec492081ab28e7317a2a62_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/c2848dd263ec492081ab28e7317a2a62_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/c2848dd263ec492081ab28e7317a2a62_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/c2848dd263ec492081ab28e7317a2a62_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/c2848dd263ec492081ab28e7317a2a62_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/c2848dd263ec492081ab28e7317a2a62_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/c2848dd263ec492081ab28e7317a2a62_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/c2848dd263ec492081ab28e7317a2a62_s=3000x_.png);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper >
                
                <Title className="title-box fs--36 title-box--invert" content={"Co děláme"}>
                </Title>

                <Text className="text-box text-box--invert" style={{"maxWidth":650}} content={"Co nás činí unikátními? Věříme, že to není jen to, co děláme, ale i to, jak to děláme. Naše produkty jsou výsledkem pečlivého výběru a neustálého testování, což zaručuje, že každý kus, který opustí naše dveře, je v souladu s nejvyššími standardy.&nbsp;"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="css-tbzkg1 --border2 --full pb--60 pt--60" animS={"2"} border={"2"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/332e6a51598f4b06ae5460628aa9db39_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/332e6a51598f4b06ae5460628aa9db39_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/332e6a51598f4b06ae5460628aa9db39_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/332e6a51598f4b06ae5460628aa9db39_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/332e6a51598f4b06ae5460628aa9db39_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/332e6a51598f4b06ae5460628aa9db39_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/332e6a51598f4b06ae5460628aa9db39_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/332e6a51598f4b06ae5460628aa9db39_s=3000x_.png);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper >
                
                <Title className="title-box fs--36 title-box--invert" content={"Staňte se našími partnery"}>
                </Title>

                <Text className="text-box text-box--invert" style={{"maxWidth":650}} content={"V Geontrade group se nebojíme myslet jinak a dělat věci jinak. Jsme zde, abychom vám pomohli odlišit se od konkurence a společně růst. Připojte se k nám a objevte, jak může být partnerství s Geontrade Group výhodou pro vaše podnikání.\n\nJsme Geontrade Group – váše brána k produktům, které definují kvalitu, inovaci a finanční úsporu."}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column className="pb--60 pt--60" name={"m7a9xz3efhf"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Prací prostředky značky See washing&nbsp;"}>
              </Title>

              <Button className="btn-box" url={"/praci-prostredek-see"} use={"url"} href={"/praci-prostredek-see"} target={"_blank"} content={"Tlačítko"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Prací prostředky značky KOHL"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={""}>
              </Text>

              <Button className="btn-box" url={"/praci-prostredek-kofl"} use={"url"} href={"/praci-prostredek-kofl"} content={"Tlačítko"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Produkty značky Master Magic"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={""}>
              </Text>

              <Button className="btn-box" url={"/produkty-znacky-master-magic"} use={"url"} href={"/produkty-znacky-master-magic"} content={"Tlačítko"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"j1mrc29j6as"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Produkty značky Frische Kraft&nbsp;"}>
              </Title>

              <Button className="btn-box" url={"/produkty-znacky-frische-kraft"} href={"/produkty-znacky-frische-kraft"} content={"Tlačítko"} use={"url"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"c87w2no8qi7"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Bunny Soft&nbsp;"}>
              </Title>

              <Button className="btn-box" url={"/praci-prostredek-see"} href={"/bunny-soft"} content={"Tlačítko"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"wvbxp8j7q7"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Trolli"}>
              </Title>

              <Button className="btn-box" content={"Tlačítko"} url={"/trolli-"} href={"/trolli-"} use={"url"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"0cn53p7d762"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Profumel&nbsp;"}>
              </Title>

              <Button className="btn-box" content={"Tlačítko"} url={"/produkty-znacky-"} href={"/produkty-znacky-"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"n0euxztu4bg"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Meglio&nbsp;"}>
              </Title>

              <Button className="btn-box" content={"Tlačítko"} url={"/produkty-znacky-meglio"} href={"/produkty-znacky-meglio"} use={"url"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"7z48ycphpe4"}>
          
          <FullmapWrap className="pb--20 pt--20">
          </FullmapWrap>

          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap className="--style2" name={"6zxru2hd4of"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center fs--18" content={"<span style=\"color: var(--black);\">Provozovatel : Geontrade export import s.r.o, Krnovská 2958/110 B OPAVA, ICO 28601203, VAT CZ28601203</span><br>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"17"} place={"OPAVA, Krnovská 2958/110b"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}} name={"paticka"}>
        </Column>


        <Column className="css-11yt4sb pb--60 pt--60" name={"4ld9rzs09ru"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/35535/2d4546e50cd441489bb8d0f717002395_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/35535/2d4546e50cd441489bb8d0f717002395_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/35535/2d4546e50cd441489bb8d0f717002395_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/35535/2d4546e50cd441489bb8d0f717002395_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/35535/2d4546e50cd441489bb8d0f717002395_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/35535/2d4546e50cd441489bb8d0f717002395_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/35535/2d4546e50cd441489bb8d0f717002395_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/35535/2d4546e50cd441489bb8d0f717002395_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--color-custom-1);\">Kontaktujte nás</span>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":1000}} content={"<span style=\"color: var(--black);\">&nbsp;Pokud chcete nabízet naše produkty ve svém obchodě, distribuovat je nebo zařadit do svého e-shopu, staňte se naším prodejcem! Přidejte se k síti spokojených prodejců, kteří nabízejí svým zákazníkům kvalitní a cenově dostupné produkty.\n\nNezapomeňte sledovat naše sociální sítě a být vždy v obraze o našich nejnovějších produktech a nabídkách. Připojte se k rodině naších značek a užijte si jedinečný zážitek s našimi produkty!</span>"}>
              </Text>

              <Form className="ff--3 fs--24 w--800 ls--08 lh--08" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit","align":"center"}]} layout={"l4"}>
              </Form>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}